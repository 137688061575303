<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <div class="search-content-holder">
                        <div class="details_section">
                            <div class="user_personal_info" style="flex: 5">
                                <div class="content">
                                    <div v-if="Object.keys(profile).length" class="profile_head">
                                        <img :src="profile.profile_image" alt=""/>
                                        <h1>{{profile.full_name}}</h1>
                                        <p style="text-align: center;margin-bottom: 0;">
                                            {{profile.taddress}}
                                        </p>

                                        <div class="progress-profile">
                                            <h4>PROFILE PROGRESS {{profile.profile_percentage}}%</h4>
                                            <v-progress-linear :rounded="true"
                                                               :value="profile.profile_percentage"></v-progress-linear>
                                        </div>

                                        <div class="special_info">
                                            <div class="info">
                                                <small>Joined</small>
                                                <p style="font-size: 13px;">{{profile.join_date_format}}</p>
                                            </div>
                                            <div class="info">
                                                <small>Children</small>
                                                <p>{{children.length}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="other_personal_info">
                                        <h4>
                                            <add-button style="width: 100%" permission="exam-create"
                                                        @action="form.dialog = true,$refs.form ? $refs.form.reset():'' ">
                                                &nbsp; Documents
                                            </add-button>
                                        </h4>
                                        <v-data-table
                                                :headers="headers"
                                                hide-default-footer :items="profile.documents"
                                                footer-props.items-per-page-options="rowsPerPageItems"
                                                :loading="form.loading">
                                            <template v-slot:item="{index, item}">
                                                <tr>
                                                    <td align="left">{{ ++index }}</td>
                                                    <td class="text-xs-left">{{ item.title }}</td>
                                                    <td class="text-xs-left">{{ item.document_type }}</td>
                                                    <!-- <td class="text-xs-left"><a :href="item.docs" target="_blank">{{item.docs
                                                        ? 'File':''}}</a></td> -->
                                                    <td class="text-xs-right" >
                                                        <a :href="item.docs" target="_blank">
                                                            <view-button :permission="item.docs ? true:false" label="Preview"/>
                                                        </a>
                                                        <delete-button permission="notice-read"
                                                                       @agree="deleteData(item.id)"/>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </div>
                            </div>
                            <div class="user_detail_info">

                                <v-card>
                                    <v-tabs
                                            v-model="tab"
                                            background-color="primary"
                                            dark
                                    >
                                        <v-tab key="One"

                                        >
                                            User Details
                                        </v-tab>
                                    </v-tabs>

                                    <v-tabs-items v-model="tab">
                                        <v-tab-item
                                                key="One"
                                        >
                                            <v-card flat>
                                                <v-card-text>
                                                    <div class="tab_box" style="padding: 0;">
                                                        <v-container style="padding: 0;" class="user-address user-profile-info"
                                                                     grid-list-md>
                                                            <v-card class="elevation-0">
                                                                <personal v-if="!updatePersonal" :user="profile"/>
                                                            </v-card>
                                                        </v-container>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="form.dialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="title">Add</span>
                </v-card-title>
                <v-card-text class="pb-1">
                    <v-form ref="form" @submit.prevent="save"
                            @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                            v-model="valid"
                            :lazy-validation="lazy"
                    >
                        <v-text-field outlined dense autocomplete="off" label="Title*"
                                      required class="pa-0 pt-3"
                                      v-model="form.title"
                                      name="title" :error-messages="form.errors.get('title')"
                                      :rules="[v => !!v || 'Title Field is required']"/>

                        <v-select outlined dense :items="documentTypes" class="pa-0 pt-3" label="Document Type"
                                  name="status" v-model="form.document_type" :error-messages="form.errors.get('status')"
                                  :rules="[v => !!v || 'Docuemnt Type Field is required']"/>

                        <v-textarea outlined dense label="Description"
                                    v-model="form.description"
                                    :rules="[v => !!v || 'Description Field is required']"/>
                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" required/>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                    <v-btn color="success" text @click="validate">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import Personal from './../../Profile/modules/personal'
    import Form from '@/library/Form'


    export default {
        components: {
            Personal,
        },
        computed: {

            isDue() {
                return (this.billSum > this.paidSum);
            },
            advanceAmount() {
                if (this.isDue) {
                    return 0;
                }
                return this.paidSum - this.billSum;
            },
            dueAmount() {
                if (this.isDue) {
                    return this.billSum - this.paidSum;
                }
                return 0;
            }

        },
        data() {
            return {
                tab:null,
                items: [
                    {icon: 'folder', iconClass: 'grey lighten-1 white--text', title: 'Photos', subtitle: 'Jan 9, 2014'},
                    {
                        icon: 'folder',
                        iconClass: 'grey lighten-1 white--text',
                        title: 'Recipes',
                        subtitle: 'Jan 17, 2014'
                    },
                    {icon: 'folder', iconClass: 'grey lighten-1 white--text', title: 'Work', subtitle: 'Jan 28, 2014'},
                ],
                valid: true,
                lazy: false,
                children: [],
                form: new Form({
                    title: '',
                    user_id: '',
                    description: '',
                    document: '',
                    document_type: '',
                }, '/api/document'),
                profile: {},
                updatePersonal: false,
                // selectedChild: false,

                series: [{
                    name: 'BILLING',
                    data: []
                }, {
                    name: 'PAID',
                    data: []
                }],
                documentTypes: [
                    {value: 'citizen', text: 'Citizen'},
                    {value: 'cv', text: 'CV'},
                    {value: 'academic', text: 'Academic'},
                    {value: 'other', text: 'Other'}
                ],

                chartOptions: {},
                statementList: [],
                headers: [
                    // {text: 'SN', align: 'left', value: 'title', sortable: false},
                    { text: "#", align: "left", value: "sno", width: 5, sortable: false },
                    {text: 'Title', align: 'left', value: 'title', sortable: false},
                    {text: 'Type', align: 'left', value: 'type', sortable: false},
                    // {text: 'File', align: 'left', value: 'docs', sortable: false},
                    {text: 'Action', align: 'left', value: 'action', sortable: false, width: 50},
                ],
                loading: false,
                billSum: 0,
                instantDiscountSum: 0,
                paidSum: 0,
                lastStatementItem: {}
            };
        },
        mounted() {
            this.fetchProfile();
            // this.fetchChildren();
        },
        methods: {
            fetchProfile() {
                const {id} = this.$route.params;
                this.$rest.get('/api/user/' + id + '/details').then(({data}) => {
                    this.profile = data;
                });
            },
            save() {
                this.form.fireFetch = false;
                this.form.user_id = this.profile.id
                this.form.store().then((data) => {
                    this.fetchProfile();
                });
            },
            handleFileUpload() {
                this.form.document = this.$refs.file.files[0];
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            deleteData(id) {
                this.form.delete(id).then((data) => {
                    this.fetchProfile();
                });
            }
        }
    }
</script>

<style lang="scss">
    /*.v-main__wrap{*/
    /*flex: 1 1 auto;*/
    /*}*/

    .adv_due_rep {
        max-width: 270px;

        .report_box_content {
            display: flex;

            .report_box {
                margin-right: 10px;
                border-radius: 20px;
                min-width: 130px;
                min-height: 150px;
                padding: 13px;
                color: white;

                div.line {
                    width: 40px;
                    height: 5px;
                    border-radius: 10px;
                    background: white;
                }

                i {
                    color: white;
                    font-size: 30px;
                }

                h2 {
                    margin-top: 15px;

                    + p {
                        font-size: 12px;
                        margin-top: 10px;
                        color: white;
                    }
                }

                &.advance_rep {
                    background: #6387a3;
                }

                &.due_rep {
                    background: #4fc2d8;
                }
            }
        }

        p {
            margin-top: 20px;
            margin-bottom: 0;
            font-size: 12px;
            color: #888;

            + h4 {
                color: #555;
                font-weight: 500;
                line-height: 1;
            }
        }

        .icon-alert {
            margin-top: 10px;
            height: 24px;
            width: 24px;
            text-align: center;
            border-radius: 50%;
            padding: 3px;

            i {
                font-size: 18px;
            }

            &.on-advance {
                background: rgba(99, 135, 163, 0.3);

                i {
                    color: rgba(99, 135, 163, 1) !important;
                }
            }

            &.on-due {
                i {
                    color: #4fc2d8 !important;
                }

                background: rgba(79, 194, 216, 0.3);
            }
        }
    }

    .billing_lagend {
        display: flex;
        padding: 0 0 0 10px;

        li {
            list-style: none;
            margin-right: 20px;
            font-weight: bold;
            color: #555;

            &:before {
                content: '';
                display: inline-block;
                margin-right: 5px;
                height: 10px;
                width: 10px;
            }

            &.bill_amount {
                &:before {
                    background: #6387a3;
                    /*"#6387a3", "#4fc2d8"*/
                }
            }

            &.paid_amount {
                &:before {
                    background: #4fc2d8;
                }
            }
        }
    }

    .light_text {
        color: #999;
    }

    .no-info-alert {
        /*background: red;*/
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        h1 {
            color: #666;

            + p {
                color: #999;
            }
        }

        align-items: center;

        .search-result {
        }
    }

    .search-content-holder {
        min-height: 450px;
        display: flex;

        .user_personal_info {
            .v-timeline {
                padding-top: 0;
            }

            flex: 3;
            overflow: hidden;
            padding: 20px 0;
            /*background-color: green;*/
            .content {
                height: 100%;
                flex: 1;
                border-right: 1px solid #e3e3e3;
                padding: 0 15px;

                .profile_head {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px dashed #e0e0e0;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    color: #666;
                    font-size: 13px;

                    img {
                        border: 5px solid #eee;
                        height: 120px;
                        width: 120px;
                        border-radius: 150px;
                    }

                    h1 {
                        font-size: 23px;
                        margin-top: 15px;
                        font-weight: normal;
                        line-height: 1;
                    }

                    p {
                        color: #888;
                        line-height: 1.5;
                    }

                    .special_info {
                        display: flex;
                        width: 97%;
                        margin-top: 15px;

                        .info {
                            background-color: transparent !important;
                            background: none;
                            width: 50%;
                            border-right: 3px solid #eee !important;
                            padding-left: 10px;
                            padding-right: 10px;
                            margin-right: 10px;
                            display: block;

                            &:last-child {
                                border-right: none !important;
                            }

                            small {
                                font-size: 10px;
                                color: #ccc;
                                font-weight: bold;
                            }

                            p {
                                font-weight: bold;
                                color: #777;
                                font-size: 16px;
                                margin-top: 0;
                                line-height: 1;
                                margin-bottom: 0;
                            }
                        }
                    }

                    .progress-profile {
                        h4 {
                            font-size: 12px;
                            margin-bottom: 0;
                            line-height: 1.5;
                        }

                        .v-progress-linear {
                            margin-top: 5px;
                            height: 5px !important;
                        }

                        margin-top: 15px;
                        width: 90%;
                    }
                }

                .other_personal_info {
                    .v-list__group__header__prepend-icon {
                        padding: 0;
                    }

                    .v-list__group__header__prepend-icon {
                        .v-icon {
                            color: #1976d2 !important
                        }
                    }

                    padding: 0 15px;
                    color: #777;

                    h4 {
                        color: #999;
                    }

                    .v-list__tile {
                        padding: 0 !important;
                    }
                }
            }

            .v-list__group--active {
                .theme--light.v-list {
                    background: rgba(0, 0, 0, .04) !important;
                }
            }

            .theme--light.v-list .v-list__group__header:hover, .theme--light.v-list .v-list__tile--highlighted, .theme--light.v-list .v-list__tile--link:hover {
                background: none !important;
            }

            .theme--light.v-list .v-list__group--active:after, .theme--light.v-list .v-list__group--active:before {
                background: none !important;
            }

            .v-list__group__header .v-list__group__header__prepend-icon {
                min-width: 45px;
            }

            .v-list__tile__sub-title {
                font-size: 12px;
            }
        }

        .user_detail_info {
            position: relative;
            margin-top: 5px;

            .guardian-graph {
                opacity: 0.2;
                right: -100px;
                position: absolute;
                bottom: 20px;
                width: 400px;
                height: 400px;
                display: flex;
            }

            .v-timeline {
                padding-top: 0 !important;
            }

            .input-label {
                color: #888;
            }

            flex: 6.5;
            padding: 0 20px;

            .caption {
                color: #777;
            }

            .v-tabs {
                a {
                    color: #333;
                }

                .theme--dark.v-icon {
                    color: #333;
                }

                a.v-tabs__item.v-tabs__item--active {
                    color: #1976d2 !important;
                    font-weight: bold;
                    background: #e3effb;
                    border-bottom: 3px solid #1976d2;

                    .theme--dark.v-icon {
                        color: #1976d2 !important;
                    }

                }
            }

            .v-tabs__container--icons-and-text .v-tabs__item {
                flex-direction: row;
            }

            .v-tabs__item {
                justify-content: center;
            }

            .v-tabs__container {
                border-bottom: 1px solid #ccc;
            }

            .v-tabs__item {
                padding: 6px 0;
            }

            .v-tabs__container--grow .v-tabs__div {
                flex: none;
            }

            .v-tabs__container--icons-and-text {
                height: 50px;
                background-color: #f6f6f6;
                border-radius: 6px;
                overflow: hidden;
            }

            /*background-color: orange;*/
        }

        .tab_box {
            /*background-color: red;*/
            padding: 15px;
            margin-top: 5px;

            .tab_title {
                font-weight: bold;
                color: #ccc;

                p {
                    margin-bottom: 0;
                }
            }

            .batch_lists_container {
                .batch_list {
                    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
                    padding: 5px 10px;
                    border-radius: 2px;
                    margin-bottom: 20px;
                    margin-top: 10px;

                    &.active {
                        border: 1px solid #b4d3f1;
                        box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1);
                    }

                    p {
                        margin-bottom: 0;
                        line-height: 1.3;
                    }

                    h3 {
                        margin-top: 0;
                        color: #666;
                        border-bottom: 1px dashed #eee;
                        margin-bottom: 10px;
                        padding-bottom: 10px;

                        i {
                            color: #1976d2 !important;
                            font-size: 18px;
                        }
                    }

                    .academic_record {
                        margin-top: 5px;
                        /*border-left: 2px solid #999;*/
                        padding: 2px 0;
                        font-size: 13px;
                        margin-bottom: 8px;
                        color: #999;
                        font-weight: bold;
                        margin-left: -7px;
                        line-height: 1;

                        i {
                            color: #999;
                            font-size: 16px;
                            line-height: 0.9;
                        }

                        span {
                            float: right;
                            font-weight: normal;
                            font-size: 12px;
                            /*text-align: right;*/
                        }
                    }
                }
            }

            .academic_report {
                display: flex;
                margin-top: 15px;

                .report_box {
                    cursor: pointer;
                    min-height: 80px;
                    background-color: white;
                    border-right: 2px;
                    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
                    border-radius: 2px;
                    flex: 1;
                    display: flex;
                    align-items: center;

                    &.active {
                        border-bottom: 5px solid #b4d3f1;
                    }

                    .chart_box {
                        height: 50px;
                        margin: 0 10px 0 8px;
                        width: 50px;
                        border-radius: 50%;
                    }

                    .chart_info {
                        /*background-color: red;*/
                        height: 30px;

                        h4 {
                            margin-bottom: 0;
                            color: #666;
                            line-height: 1;
                        }

                        p {
                            font-size: 12px;
                            color: #999;
                            margin-top: 0;
                            /*font-weight: bold;*/
                        }
                    }

                }

                .assignment_rep {
                    margin-right: 10px;

                    .chart_box {
                        background-color: #724a9f;
                    }
                }

                .attendance_rep {
                    margin-right: 10px;

                    .chart_box {
                        background-color: #1976d2 !important;
                    }
                }

                .subject_rep {
                    margin-right: 10px;

                    .chart_box {
                        background-color: #ff994f !important;
                    }
                }

                .subject_rep {
                    flex: 1;
                }
            }
        }
    }

    .hide_search {
        height: 0 !important;
    }

    .details_section {
        display: flex;
        flex: 1;
    }

    .page-layout {
        border-top: 10px solid #ccc;

        .v-card__title {
            font-weight: normal;
            color: #333;
            font-size: 18px !important;

            .head_icon.v-icon {
                font-size: 18px;
                background-color: #ccc;
                color: #333;
                border-radius: 20px;
                padding: 3px;
            }
        }
    }

    .aio .v-list__tile__action, .aio .v-list__tile__avatar {
        margin-right: 10px;
    }

    .aio {
        .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
            box-shadow: none;
        }

        .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
            background-color: transparent;
        }

        .v-btn--large {
            font-weight: bold;
            font-size: 14px;
            color: #888;
            padding: 0;
        }

        .v-btn--active:before, .v-btn:focus:before, .v-btn:hover:before {
            background-color: transparent;
        }

        .search-content-holder {
            padding: 10px 20px;
            min-height: 750px;
        }

        .v-list__tile__action, .v-list__tile__avatar {
            min-width: 35px;
        }

        .v-list__tile__sub-title, .v-list__tile__title {
            white-space: normal;
        }
    }

    .child-tab {
        padding: 0;
        display: flex;
        align-items: flex-end;

        ul {
            padding: 0;
            margin-left: auto;
        }

        li {
            list-style: none;

            a {
                text-decoration: none;
                background-color: #e3effb;
                color: #666 !important;
                margin-left: 3px;
                padding: 8px 15px;

                &.active {
                    border-bottom: 3px solid #1976d2;
                    color: #1976d2 !important;
                    font-weight: bold;

                }
            }

            display: inline-block;
        }
    }
    .v-tab.v-tab--active {
        color: white !important;
    }

</style>